<template>
  <div class="w_home w_home_bg">
    <div class="w_home_body">
      <img class="company" src="../assets/images/company_name_cn.png" />
      <div class="btn">
        <router-link :to="{ name: 'materiallist' }" class="item"><img src="../assets/images/link_2_cn.png" /></router-link>
        <a class="item" target="_blank" href="http://www.chinaubo.com.cn/" ><img class="item" src="../assets/images/link_1_cn.png" /></a>

      </div>
    </div>
  </div>
</template>

<script>

</script>
